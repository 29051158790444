import React, { useEffect, useMemo, useState } from "react";
import { Grid, styled, Typography } from "@mui/material";
import { FormSelect } from "@components/FormSelect";
import {
  adminDirectoryGet,
  adminMetricQaBugOverviewGet,
  AdminMetricQaBugOverviewGetPlatform,
  useAdminBuildAllGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";
import { TimeFilter } from "@pages/dashboard/components/TimeFilter";
import NoDataIcon from "@assets/icons/no-data-icon.svg";
import { Column, DataGridTable } from "@components/DataGridTable";
import { useQuery } from "@tanstack/react-query";

const StyledGrid = styled("div")`
  border: 1px solid rgba(0, 0, 0, 0.23);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  margin-top: 48px;
  margin-bottom: 48px;
  margin-left: 55px;
  width: calc(100vw - 340px);

  .MuiTable-root {
    .hidden-col {
      background-color: #ededed !important;
    }
    .expand-btn {
      padding: 0 !important;
      background-color: #ededed !important;
    }
    .expand-btn-body {
      padding: 0 !important;
    }
  }
  td {
    font-size: 14px;
  }
  .pinned-body {
    background-color: #fff !important;
  }
  .rework {
    color: #1e293b;
    font-weight: 700;
  }
  .sum-row {
    background-color: #f3f4f7 !important;
  }
  tr:last-child {
    td:first-child,
    td:last-child {
      border-bottom-left-radius: 8px;
    }
    td:last-child {
      border-bottom-right-radius: 8px;
    }
  }
`;

const StyledDiv = styled("div")`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 16px;
  overflow: hidden;
  .MuiButtonBase-root {
    width: 100%;
  }
  div {
    width: 180px;
    align-items: center;
    justify-content: center;
    height: 33px;
    color: #64748b;
    font-weight: 600;
    fieldset {
      border-color: #64748b !important;
    }
  }

  @media (max-width: 1400px) {
    div {
      width: 100%;
    }
  }
`;

const StyledBoxValue = styled(Typography)`
  color: #1e293b;
  font-weight: 600;
  font-size: 2vw;
  line-height: 48px;

  @media (min-width: 1300px) {
    font-size: 32px;
  }
`;

type BUG_CARD_OVERVIEW = {
  label: string;
  BACKLOG: number;
  READY_COMMITTED: number;
  TODAYS_PLAN: number;
  IN_PROGRESS: number;
  REVIEW: number;
  QA: number;
  ON_HOLD: number;
  DONE: number;
  isRework?: boolean;
  isSumRow?: boolean;
  isHours?: boolean;
};

const formatValue = (value: number | undefined, isHours?: boolean): string => {
  if (!value) {
    return "0";
  }
  if (value === 0) {
    return "0";
  }
  if (isHours) {
    const hours = Math.floor(value);
    const minutes = Math.round((value - hours) * 60);
    return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
  }
  return value.toFixed(0);
};

const platformOptions = [
  {
    label: "All",
    value: "ALL"
  },
  {
    label: "iOS",
    value: "IOS"
  },
  {
    label: "AND",
    value: "AND"
  },
  {
    label: "WEB",
    value: "WEB"
  },
  {
    label: "MOB",
    value: "MOB"
  }
];

export const BugCardOverview = () => {
  const [buildId, setBuildId] = useState<string | undefined>(undefined);
  const [rows, setRows] = useState<BUG_CARD_OVERVIEW[]>([]);
  const [resourceId, setRecourceId] = useState<string | undefined>("");
  const [plaftomFilter, setPlatformFilter] = useState<
    AdminMetricQaBugOverviewGetPlatform & "ALL"
  >("ALL");
  const [resourceOptions, setResourceOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [selectedTimeRange, setSelectedTimeRange] = useState<
    [string | null, string | null]
  >([new Date().toISOString(), null]);
  const [timeFilter, setTimeFilter] = useState("TODAY");
  const [refreshKey, setRefreshKey] = useState(0);

  const { data: builds, isLoading: isLoading } = useAdminBuildAllGet();

  const { data: companyDirectory, isLoading: isResourceLoading } = useQuery(
    ["companyDirectory"],
    () =>
      adminDirectoryGet({
        pageSize: 1000
      }),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: true
    }
  );

  const buildsOptions = useMemo(() => {
    const build =
      builds?.data?.builds?.map((build) => ({
        label: `${build.buildNumber} - ${build.platform}`,
        value: build.buildNumber,
        platform: build.platform
      })) || [];

    return [
      {
        label: "All",
        value: "ALL",
        platform: "ALL"
      },
      ...build
    ];
  }, [builds]);

  const resources = useMemo(() => {
    let options: Array<{ label: string; value: string }> = [];
    if (companyDirectory?.data.persons) {
      options = companyDirectory.data.persons
        .filter((resource) => resource.sportsgravyUser)
        .map((resource) => ({
          label: `${resource.firstName} ${resource.lastName}`,
          value: resource?.sportsgravyUser?.jiraAccountId as string
        }));
    }
    options.unshift({
      label: "All",
      value: "ALL"
    });
    setResourceOptions(options);
    return options;
  }, [companyDirectory?.data]);

  const query = useMemo(() => {
    const buildQuery = {} as {
      date: string;
      platform?: AdminMetricQaBugOverviewGetPlatform;
      resourceId?: string;
      build?: number;
    };
    buildQuery.date = selectedTimeRange[0] || "";
    if (buildId && buildId !== "ALL") buildQuery.build = Number(buildId);
    if (resourceId && resourceId !== "ALL") buildQuery.resourceId = resourceId;
    if (plaftomFilter && plaftomFilter !== "ALL")
      buildQuery.platform = plaftomFilter;

    return buildQuery;
  }, [selectedTimeRange, buildId, resourceId, plaftomFilter]);

  const { data: bco, isLoading: isBugOverviewLoading } = useQuery(
    [
      "adminBugCardOverview",
      timeFilter,
      buildId,
      plaftomFilter,
      resourceId,
      selectedTimeRange
    ],
    () => adminMetricQaBugOverviewGet(query),
    {
      staleTime: 1000 * 60 * 10 * 60,
      cacheTime: 1000 * 60 * 10 * 60
    }
  );

  useEffect(() => {
    if (!buildId) setBuildId(buildsOptions[0]?.value);
  }, [buildsOptions]);

  const COLUMNS: Column[] = [
    {
      field: "resource",
      headerName: "Resource",
      align: "left",
      width: 200,
      borderRight: "1px solid #E5E5E5",
      renderCell: (row: BUG_CARD_OVERVIEW) => {
        return <span>{row.label}</span>;
      },
      setBodyClassName: (row) => {
        return `${row.isRework ? "rework" : ""} ${row.isSumRow ? "sum-row" : ""}`;
      }
    },
    {
      field: "backlog",
      headerName: "Backlog",
      width: 100,
      borderRight: "1px solid #E5E5E5",
      setBodyClassName: (row) => {
        return `${row.isRework ? "rework" : ""} ${row.isSumRow ? "sum-row" : ""}`;
      },
      renderCell: (row: BUG_CARD_OVERVIEW) => {
        return <span>{formatValue(row.BACKLOG, row.isHours)}</span>;
      }
    },
    {
      field: "READY_COMMITTED",
      headerName: "Ready / Committed",
      width: 100,
      borderRight: "1px solid #E5E5E5",
      setBodyClassName: (row) => {
        return `${row.isRework ? "rework" : ""} ${row.isSumRow ? "sum-row" : ""}`;
      },
      renderCell: (row: BUG_CARD_OVERVIEW) => {
        return <span>{formatValue(row?.READY_COMMITTED, row.isHours)}</span>;
      }
    },
    {
      field: "TODAYS_PLAN",
      headerName: "Today’s Plan",
      width: 100,
      borderRight: "1px solid #E5E5E5",
      setBodyClassName: (row) => {
        return `${row.isRework ? "rework" : ""} ${row.isSumRow ? "sum-row" : ""}`;
      },
      renderCell: (row: BUG_CARD_OVERVIEW) => {
        return <span>{formatValue(row?.TODAYS_PLAN, row.isHours)}</span>;
      }
    },
    {
      field: "IN_PROGRESS",
      headerName: "In Progress",
      width: 100,
      borderRight: "1px solid #E5E5E5",
      setBodyClassName: (row) => {
        return `${row.isRework ? "rework" : ""} ${row.isSumRow ? "sum-row" : ""}`;
      },
      renderCell: (row: BUG_CARD_OVERVIEW) => {
        return <span>{formatValue(row?.IN_PROGRESS, row.isHours)}</span>;
      }
    },
    {
      field: "REVIEW",
      headerName: "Review",
      width: 100,
      borderRight: "1px solid #E5E5E5",
      setBodyClassName: (row) => {
        return `${row.isRework ? "rework" : ""} ${row.isSumRow ? "sum-row" : ""}`;
      },
      renderCell: (row: BUG_CARD_OVERVIEW) => {
        return <span>{formatValue(row?.REVIEW, row.isHours)}</span>;
      }
    },
    {
      field: "QA",
      headerName: "QA",
      width: 100,
      borderRight: "1px solid #E5E5E5",
      setBodyClassName: (row) => {
        return `${row.isRework ? "rework" : ""} ${row.isSumRow ? "sum-row" : ""}`;
      },
      renderCell: (row: BUG_CARD_OVERVIEW) => {
        return <span>{formatValue(row?.QA, row.isHours)}</span>;
      }
    },
    {
      field: "ON_HOLD",
      headerName: "On Hold",
      width: 100,
      borderRight: "1px solid #E5E5E5",
      setBodyClassName: (row) => {
        return `${row.isRework ? "rework" : ""} ${row.isSumRow ? "sum-row" : ""}`;
      },
      renderCell: (row: BUG_CARD_OVERVIEW) => {
        return <span>{formatValue(row?.ON_HOLD, row.isHours)}</span>;
      }
    },
    {
      field: "DONE",
      headerName: "Done",
      borderRight: "1px solid #E5E5E5",
      width: 100,
      setBodyClassName: (row) => {
        return `${row.isRework ? "rework" : ""} ${row.isSumRow ? "sum-row" : ""}`;
      },
      renderCell: (row: BUG_CARD_OVERVIEW) => {
        return <span>{formatValue(row?.DONE, row.isHours)}</span>;
      }
    },
    {
      field: "Total",
      headerName: "Total",
      width: 100,
      setBodyClassName: (row) => {
        return `${row.isRework ? "rework" : ""} ${row.isSumRow ? "sum-row" : ""}`;
      },
      renderCell: (row: BUG_CARD_OVERVIEW) => {
        const total =
          (row?.BACKLOG ?? 0) +
          (row?.READY_COMMITTED ?? 0) +
          (row.TODAYS_PLAN ?? 0) +
          (row.IN_PROGRESS ?? 0) +
          (row.REVIEW ?? 0) +
          (row.QA ?? 0) +
          (row.ON_HOLD ?? 0) +
          (row.DONE ?? 0);
        return <span>{formatValue(total, row.isHours)}</span>;
      }
    }
  ];

  useEffect(() => {
    if (bco?.data.data) {
      const data = bco.data.data;
      const groupedData = data.reduce(
        (acc, issue) => {
          if (issue.assigneeId) {
            if (!acc[issue.assigneeId]) {
              acc[issue.assigneeId] = {
                label:
                  `${issue.assignee?.person?.firstName} ${issue.assignee?.person?.lastName}`.replace(
                    "-",
                    ""
                  ) || "",
                BACKLOG: 0,
                READY_COMMITTED: 0,
                TODAYS_PLAN: 0,
                IN_PROGRESS: 0,
                REVIEW: 0,
                QA: 0,
                ON_HOLD: 0,
                DONE: 0
              };
            }

            const status = issue.status as keyof BUG_CARD_OVERVIEW;
            if (acc[issue.assigneeId][status] === undefined) {
              if (issue.sprintId) acc[issue.assigneeId][status] = 0;
              else acc[issue.assigneeId].BACKLOG = 0;
            }

            if (!issue.sprintId) {
              acc[issue.assigneeId].BACKLOG++;
            } else acc[issue.assigneeId][status]++;
          }
          return acc;
        },
        {} as Record<string, BUG_CARD_OVERVIEW>
      );

      const reworkData = data.reduce(
        (acc, issue) => {
          const reworkLabel = issue.labels?.find((label) =>
            label.startsWith("Rework-")
          );
          if (reworkLabel) {
            if (!acc.reworkCounts[reworkLabel]) {
              acc.reworkCounts[reworkLabel] = {
                label: reworkLabel.replace("-", " "),
                BACKLOG: 0,
                READY_COMMITTED: 0,
                TODAYS_PLAN: 0,
                IN_PROGRESS: 0,
                REVIEW: 0,
                QA: 0,
                ON_HOLD: 0,
                DONE: 0,
                isRework: true
              };
            }
            const status = issue.status as keyof BUG_CARD_OVERVIEW;
            if (acc.reworkCounts[reworkLabel][status] === undefined) {
              if (issue.sprintId) acc.reworkCounts[reworkLabel][status] = 0;
              else acc.reworkCounts[reworkLabel].BACKLOG = 0;
            }
            if (issue.sprintId) acc.reworkCounts[reworkLabel][status]++;
            else acc.reworkCounts[reworkLabel].BACKLOG++;

            if (acc.reworkEstimates[status] === undefined) {
              if (issue.sprintId) acc.reworkEstimates[status] = 0;
              else acc.reworkEstimates.BACKLOG = 0;
            }

            if (issue.sprintId)
              acc.reworkEstimates[status] +=
                (issue.originalEstimate || 0) / 3600;
            else
              acc.reworkEstimates.BACKLOG +=
                (issue.originalEstimate || 0) / 3600;

            if (acc.reworkTimeSpent[status] === undefined) {
              if (issue.sprintId) acc.reworkTimeSpent[status] = 0;
              else acc.reworkTimeSpent.BACKLOG = 0;
            }
            if (issue.sprintId)
              acc.reworkTimeSpent[status] += (issue.timeSpent || 0) / 3600;
            else acc.reworkTimeSpent.BACKLOG += (issue.timeSpent || 0) / 3600;
          }
          return acc;
        },
        {
          reworkCounts: {} as Record<string, BUG_CARD_OVERVIEW>,
          reworkEstimates: {
            label: "Total Est Rework Time",
            BACKLOG: 0,
            READY_COMMITTED: 0,
            TODAYS_PLAN: 0,
            IN_PROGRESS: 0,
            REVIEW: 0,
            QA: 0,
            ON_HOLD: 0,
            DONE: 0,
            isRework: true,
            isHours: true,
            isSumRow: true
          } as BUG_CARD_OVERVIEW,
          reworkTimeSpent: {
            label: "Total Act Rework Time",
            BACKLOG: 0,
            READY_COMMITTED: 0,
            TODAYS_PLAN: 0,
            IN_PROGRESS: 0,
            REVIEW: 0,
            QA: 0,
            ON_HOLD: 0,
            DONE: 0,
            isRework: true,
            isHours: true,
            isSumRow: true
          } as BUG_CARD_OVERVIEW
        }
      );
      Object.keys(reworkData.reworkEstimates).forEach((status) => {
        if (typeof reworkData.reworkEstimates[status] === "number") {
          reworkData.reworkEstimates[status] = parseFloat(
            reworkData.reworkEstimates[status].toFixed(2)
          );
        }
      });

      Object.keys(reworkData.reworkTimeSpent).forEach((status) => {
        if (typeof reworkData.reworkTimeSpent[status] === "number") {
          reworkData.reworkTimeSpent[status] = parseFloat(
            reworkData.reworkTimeSpent[status].toFixed(2)
          );
        }
      });

      const formattedReworkData = [
        ...Object.values(reworkData.reworkCounts),
        reworkData.reworkEstimates,
        reworkData.reworkTimeSpent
      ];

      const summaryRows = data.reduce(
        (acc, issue) => {
          const status = issue.status as keyof BUG_CARD_OVERVIEW;
          if (acc.totalCards[status] === undefined) {
            if (issue.sprintId) {
              acc.totalCards[status] = 0;
              acc.totalOriginalEstimate[status] = 0;
              acc.totalTimeSpent[status] = 0;
            } else {
              acc.totalCards.BACKLOG = 0;
              acc.totalOriginalEstimate.BACKLOG = 0;
              acc.totalTimeSpent.BACKLOG = 0;
            }
          }

          if (!issue.sprintId) acc.totalCards.BACKLOG++;
          else acc.totalCards[status]++;

          if (issue.sprintId) {
            acc.totalOriginalEstimate[status] +=
              (issue.originalEstimate || 0) / 3600;
            acc.totalTimeSpent[status] += (issue.timeSpent || 0) / 3600;
          } else {
            acc.totalOriginalEstimate.BACKLOG +=
              (issue.originalEstimate || 0) / 3600;
            acc.totalTimeSpent.BACKLOG += (issue.timeSpent || 0) / 3600;
          }
          return acc;
        },
        {
          totalCards: {
            label: "Total Cards",
            BACKLOG: 0,
            READY_COMMITTED: 0,
            TODAYS_PLAN: 0,
            IN_PROGRESS: 0,
            REVIEW: 0,
            QA: 0,
            ON_HOLD: 0,
            DONE: 0,
            isSumRow: true,
            isRework: true
          } as BUG_CARD_OVERVIEW,
          totalOriginalEstimate: {
            label: "Total Est Time",
            BACKLOG: 0,
            READY_COMMITTED: 0,
            TODAYS_PLAN: 0,
            IN_PROGRESS: 0,
            REVIEW: 0,
            QA: 0,
            ON_HOLD: 0,
            DONE: 0,
            isRework: true,
            isHours: true,
            isSumRow: true
          } as BUG_CARD_OVERVIEW,
          totalTimeSpent: {
            label: "Total Act Time",
            BACKLOG: 0,
            READY_COMMITTED: 0,
            TODAYS_PLAN: 0,
            IN_PROGRESS: 0,
            REVIEW: 0,
            QA: 0,
            ON_HOLD: 0,
            DONE: 0,
            isRework: true,
            isHours: true,
            isSumRow: true
          } as BUG_CARD_OVERVIEW
        }
      );
      Object.keys(summaryRows).forEach((key) => {
        const row = summaryRows[key as keyof typeof summaryRows];
        (
          [
            "BACKLOG",
            "READY_COMMITTED",
            "TODAYS_PLAN",
            "IN_PROGRESS",
            "REVIEW",
            "QA",
            "ON_HOLD",
            "DONE"
          ] as (keyof BUG_CARD_OVERVIEW)[]
        ).forEach((status) => {
          row[status] = parseFloat(row[status].toFixed(2));
        });
      });

      const resourceIds = Object.keys(groupedData);
      if (resourceId === "ALL" || buildId === "ALL") {
        setResourceOptions([
          {
            label: "All",
            value: "ALL"
          },
          ...resources
            .filter((resource) => resourceIds.includes(resource.value))
            .map((resource) => ({
              label: resource.label,
              value: resource.value
            }))
        ]);
      }

      setRows([
        ...Object.values(groupedData),
        ...Object.values(summaryRows),
        ...Object.values(formattedReworkData)
      ]);
    }
  }, [bco]);

  return (
    <StyledGrid className="bug-card-overview">
      <div
        style={{
          minHeight: isLoading || isBugOverviewLoading ? "400px" : "0px",
          minWidth: "50vw",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "24px",
            justifyContent: "space-between"
          }}
        >
          <div>
            <Typography
              style={{
                color: "#1E293B",
                fontWeight: 700,
                fontSize: "16px"
              }}
            >
              Bug Card Overview
            </Typography>
            <Typography
              style={{
                color: "#64748B",
                fontWeight: "500",
                fontSize: "14px"
              }}
            >
              Quick Insights related to SportGravy
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "16px"
            }}
          >
            <StyledDiv>
              <FormSelect
                value={plaftomFilter}
                onChange={(e) => {
                  setPlatformFilter(e.target.value);
                  setBuildId("ALL");
                  setRecourceId("ALL");
                }}
                options={platformOptions}
                name="platform"
              />
              {plaftomFilter !== "WEB" && (
                <FormSelect
                  options={
                    plaftomFilter === "ALL"
                      ? buildsOptions
                      : plaftomFilter === "MOB"
                        ? buildsOptions.filter(
                            (build) =>
                              build.platform === "IOS" ||
                              build.platform === "AND" ||
                              build.platform === "ALL"
                          )
                        : buildsOptions.filter(
                            (build) =>
                              build.platform === plaftomFilter ||
                              build.platform === "ALL"
                          )
                  }
                  name="build"
                  onChange={(e) => {
                    setBuildId(e.target.value);
                    setRecourceId("ALL");
                  }}
                  value={buildId}
                />
              )}
              <FormSelect
                options={resourceOptions}
                name="resource"
                onChange={(e) => {
                  setRecourceId(e.target.value);
                }}
                value={resourceId}
              />
            </StyledDiv>
            <TimeFilter
              selectedTimeRange={selectedTimeRange}
              setRefreshKey={setRefreshKey}
              refreshKey={refreshKey}
              setSelectedTimeRange={setSelectedTimeRange}
              isQaMetric={false}
              defaultTimeFilter="TODAY"
              setSelectedTimeFilter={setTimeFilter}
              customTimeFilterOptions={[
                { label: "Today", value: "TODAY" },
                { label: "Yesterday", value: "YESTERDAY" },
                { label: "Custom", value: "CUSTOM" }
              ]}
            />
          </div>
        </div>{" "}
        <Loader
          isLoading={isLoading || isBugOverviewLoading || isResourceLoading}
        >
          <Grid item xs={12}>
            {(!bco || !bco?.data) && !isLoading ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto",
                    height: "400px"
                  }}
                >
                  <img src={NoDataIcon} style={{ width: "64px" }} />
                  <Typography
                    style={{
                      color: "#64748b",
                      fontSize: "14px",
                      fontWeight: 500,
                      padding: "16px 24px"
                    }}
                  >
                    No data available
                  </Typography>
                </div>
              </>
            ) : (
              <Grid container direction="row" item>
                {bco &&
                  bco.data.insights &&
                  (
                    bco.data.insights as Array<{
                      label: string;
                      value: number;
                      type?: string;
                    }>
                  ).map((con, index) => {
                    return (
                      <Grid
                        item
                        container
                        direction="column"
                        key={con.label}
                        xs={index < 4 ? 3 : 2}
                        minHeight="121px"
                        padding="24px"
                        alignItems="center"
                        justifyContent="center"
                        style={{
                          border: "1px solid rgba(229, 229, 229, 1)"
                        }}
                      >
                        <Grid item>
                          <Typography
                            style={{
                              color: "#64748B",
                              fontWeight: 500,
                              fontSize: "14px",
                              lineHeight: "21px"
                            }}
                          >
                            {con.label}
                          </Typography>
                        </Grid>
                        <Grid item>
                          {(con.type === "text" || !con.type) && (
                            <StyledBoxValue>{con.value}</StyledBoxValue>
                          )}
                          {con.type === "day" && (
                            <StyledBoxValue>
                              {isNaN(con.value) ? 0 : con.value}{" "}
                              {con.value > 1 ? "Days" : "Day"}
                            </StyledBoxValue>
                          )}
                        </Grid>
                      </Grid>
                    );
                  })}
              </Grid>
            )}
            {bco && bco?.data && (
              <div>
                <DataGridTable
                  rows={rows}
                  columns={COLUMNS}
                  pinnedColumns={["resource"]}
                />
              </div>
            )}
          </Grid>
        </Loader>
      </div>
    </StyledGrid>
  );
};
